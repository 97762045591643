<template>
  <div class="about">
    <v-container>
      <h1 class="title1" style="text-align: left;color: #F69946;">{{ $t("contactUs") }}</h1>

      <v-row v-for="(d,i) in dataList" :key="i" style="margin-bottom: 100px">
          <v-col cols="12" md="7" sm="7"  class="animate__animated animate__delay-1s  animate__slideInUp">

            <div class="subTitle1" style="text-align: left" v-html="getTranslate(d.title)">
            </div>

            <v-list three-line>
              <template>
                <v-list-item>
                  <v-list-item-action>
                    <v-btn fab style="color: green">
                      <v-icon>mdi-map-marker-radius</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("visitUs") }}</v-list-item-title>
                    <v-list-item-subtitle v-html="getTranslate(d.address)"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <v-list two-line>
              <template>
                <v-list-item>
                  <v-list-item-action>
                    <v-btn fab style="color: green">
                      <v-icon>mdi-email</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("mailUs") }}</v-list-item-title>
                    <v-list-item-subtitle v-html="d.email"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <v-list two-line>
              <template>
                <v-list-item>
                  <v-list-item-action>
                    <v-btn fab style="color: green">
                      <v-icon>mdi-phone</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("callUs") }}</v-list-item-title>
                    <v-list-item-subtitle v-html="d.phoneNumber"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>

          </v-col>
          <v-col cols="12" md="5" sm="5">
            <v-img :src="d.url"></v-img>
          </v-col>
          <v-divider inset></v-divider>
      </v-row>
<!--      <v-row>-->


<!--        <v-col cols="12" sm="12" md="12" style="text-align: center">-->

<!--          <img class="text-bg" v-if="$vuetify.breakpoint.mobile"-->
<!--               style="width: 55%;transform: rotate(230deg);z-index: 0;margin-top: 40px;margin-bottom: 40px"-->
<!--               src="https://firebasestorage.googleapis.com/v0/b/phoupoy-ricemill.appspot.com/o/other%2Fphoto_2019-10-15_17-38-11.jpg?alt=media&token=39580496-f4c4-4412-a5ad-00b6e6083141"-->
<!--               alt="goal">-->
<!--          <img class="text-bg" v-if="!$vuetify.breakpoint.mobile"-->
<!--               style="width: 450px;transform: rotate(230deg);z-index: 0;margin-top: 40px;margin-bottom: 40px"-->
<!--               src="https://firebasestorage.googleapis.com/v0/b/phoupoy-ricemill.appspot.com/o/other%2Fphoto_2019-10-15_17-38-11.jpg?alt=media&token=39580496-f4c4-4412-a5ad-00b6e6083141"-->
<!--               alt="goal">-->
<!--        </v-col>-->
<!--      </v-row>-->
    </v-container>
  </div>
</template>
<!--<script src="https://smtpjs.com/v3/smtp.js">-->
<!--</script>-->
<script>
import {Constants} from "@/libs/constant";
import axios from "axios";

export default {
  data() {
    return {
      dataList: []
    }
  },
  methods: {
    fetchContact() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getContact;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.dataList = res.data.data;
              }
            },
            error => {
              reject(error);

            }
        );
      });
    },
    sendEmail(){

      //26e8da6a-5a4f-4666-9600-435111485850
      // Email.send({
      //   Host : "smtp.yourisp.com",
      //   Username : "username",
      //   Password : "password",
      //   To : 'them@website.com',
      //   From : "you@isp.com",
      //   Subject : "This is the subject",
      //   Body : "And this is the body"
      // }).then(
      //     message => alert(message)
      // );
    }
  },
  created() {
    this.fetchContact();
  }
}
</script>